<template>
  <div id="page-post">
    <div class="xs-p-20 xs-fill-white">
      <div class="lg-container content-box">
        <div v-html="content"> </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    type: {
      type: String
    },
    name: {
      type: String
    }
  },
  mounted () {
    let page = {}
    switch (this.type) {
      case 'faq':
        page = {screen: {'name': 'support-help.faqs', 'type': 'support-help'}}
        break
      case 'cmn_commercial_transactions':
        page = {screen: {'name': 'general-info.about-us', 'type': 'general-info'}}
        break
      default:
        let pre = this.$route.path.split('/')
        if (pre.length > 1) {
          pre = pre[1]
        } else if (pre.length === 1) {
          pre = pre[0]
        } else {
          pre = ''
        }
        page = {screen: {'name': 'general-info.' + pre, 'type': 'general-info'}}
        break
    }
    if (!this.empty(page)) {
      this.eventTrackingScreenLoad(this, {screen: page})
    }
    this.$root.$emit('onChangeMetaTitle', this.$t(this.$route.meta.key_title))
    this.initData()
  },
  data () {
    return {
      content: ''
    }
  },
  methods: {
    initData () {
      var vm = this
      /* this.axios.apiContent.privacy(function (response) {
        vm.content = response.data.data.item.content
      }, function (error) {

      }) */
      this.axios.apiContent.getPost(vm.type, function (response) {
        if (response && response.data && response.data.data && response.data.data.item) {
          vm.content = response.data.data.item.content
        }
      }, function (error) {

      })
    }
  }
}
</script>
<style scoped>
  li.policy_item {
    list-style: none;
  }

  .main-content--privacy-policy .policy_content .policy_list .policy_item {
    padding: 5px 0px;
  }

  li.policy_item strong {
    display: block;
    font-weight: normal;
    padding: 30px 0 10px;
  }

  li.policy_item ul {
    list-style: decimal;
  }

  p {
    font-size: 15px;
  }
</style>
